import { type TabItem, reservationUITab } from '~/config/reservations';
import { useSharedStore } from '~/store/useSharedStore';
import type { InitialReservation, Reservation } from '~/types/reservations';

export const useReservationStore = defineStore('useReservationStore', () => {
  const sanctumFetch = useSanctumClient();
  const useShareStore = useSharedStore();
  const { getUrlReservation, setCurrentReservationId } = toRefs(useShareStore);

  const tabItems = ref<TabItem[]>(reservationUITab);
  const reservations = ref([]);

  const createReservation = async (payload: InitialReservation) => {
    return new Promise((resolve, reject) => {
      try {
        const response = sanctumFetch('/api/v1/reservations', {
          method: 'POST',
          body: payload,
        });
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  };

  const updateReservationItem = async (id: number, payload: Reservation) => {
    try {
      return await sanctumFetch(`/api/v1/reservations/${id}`, {
        method: 'PUT',
        body: payload,
      });
    } catch (e) {
      return e;
    }
  };

  const removeReservationItem = async (id: number) => {
    try {
      return await sanctumFetch(`/api/v1/reservations/${id}`, {
        method: 'DELETE',
      });
    } catch (e) {
      return e;
    }
  };

  const addReservationNotes = async (payload) => {
    return await sanctumFetch(`${getUrlReservation.value}/notes`, {
      method: 'POST',
      body: payload,
    });
  };

  const fetchReservationNotes = async () => {
    return await sanctumFetch(`${getUrlReservation.value}/notes`);
  };

  const fetchReservationGuests = async () => {
    return await sanctumFetch(`${getUrlReservation.value}/guests`);
  };

  return {
    tabItems,
    reservations,
    createReservation,
    updateReservationItem,
    removeReservationItem,
    fetchReservationGuests,
    addReservationNotes,
    fetchReservationNotes,
    setCurrentReservationId,
  };
});

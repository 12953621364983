export interface TabItem {
  value: string;
  label: string;
  icon: string;
  component: string;
  editable?: boolean;
  disableOnCreate?: boolean;
}

export const reservationUITab: TabItem[] = [
  {
    value: 'reservation',
    label: 'Reservation',
    icon: 'lucide:calendar-days',
    component: 'ReservationForm',
    editable: true,
    disableOnCreate: false,
  },
  {
    value: 'guest',
    label: 'Guest Information',
    icon: 'lucide:users',
    component: 'ReservationGuestInformation',
    editable: true,
    disableOnCreate: true,
  },
  {
    value: 'documents',
    label: 'Documents',
    icon: 'lucide:file-text',
    component: 'ReservationDocuments',
    editable: false,
    disableOnCreate: true,
  },
  {
    value: 'membership',
    label: 'Membership',
    icon: 'lucide:shield',
    component: 'ReservationMembership',
    editable: false,
    disableOnCreate: true,
  },
  {
    value: 'notes',
    label: 'Notes',
    icon: 'lucide:notebook',
    component: 'ReservationNotes',
    editable: false,
    disableOnCreate: true,
  },
];
